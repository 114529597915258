import React, { useMemo, useState, useEffect } from "react";
import { FormGroup, Input } from "reactstrap";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { useJnx } from "../../util/jnx";
import LookupApi from "../../api/LookupApi";
import getPathFromId from "../../util/getPathFromId";

function CheckboxField(props) {
    const {
        formData,
        disabled,
        formContext,
        formContext: {
            setFormDataValues,
            sideChannel
        },
        idSchema: { $id },
        schema: { title },
        uiSchema: {
            'ui:readonly': uiReadonly,
            'akc:requiredIfVisible': requiredIfVisible,
            'ui:hideAttr': uiHideAttr,
            'ui:hideOnEmpty': hideOnEmpty,
            'ui:readonlyIf': uiReadonlyIfExpr,
            'ui:showIfAsync': showIfAsyncExpr,
            'ui:addOrganizationalUnitsToContext': addOrganizationalUnitsToContext = false,
            'ui:labelExpr': labelExpr
        },
        readonly: propReadonly,
        required,
        onChange: propOnChange
    } = props;

    const path = useMemo(() => getPathFromId($id), [$id]);
    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const readonlyIfJnx = useJnx(uiReadonlyIfExpr);
    const readOnlyIf = useMemo(() => (
        readonlyIfJnx && readonlyIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
        })
    ), [formData, rootFormData, formContext]);

    const requiredField = !!required || !!requiredIfVisible
    const readonly = uiReadonly || propReadonly || readOnlyIf;

    function onChange({ target: { checked } }) {
        propOnChange(checked);
    }

    if (uiHideAttr && readonly) {
        return null;
    }

    const organizationalUnits = useMemo(async () => {

        if (!addOrganizationalUnitsToContext) return [];

        const resp = await LookupApi.getOrganizationalUnits("", true);
        return resp;

    }, [addOrganizationalUnitsToContext]);

    const showIfAsyncJnx = useJnx(showIfAsyncExpr);

    const [showIfAsyncResult, setShowIfAsyncResult] = useState(true);

    useEffect(() => {
        if (!showIfAsyncJnx) return;

        showIfAsyncJnx.evalAsync(rootFormData || {}, path, {
            root: rootFormData,
            formContext,
            organizationalUnits
        }).then(result=>{
            setShowIfAsyncResult(result);
        })
    }, [showIfAsyncJnx, rootFormData, formContext, organizationalUnits, path]);
    
    
    const dynamicLabelJnx = useJnx(labelExpr);
    const [dynamicLabel, setDynamicLabel] = useState(title);

    useEffect(() => {
        if (dynamicLabelJnx) {
            const evaluateDynamicLabel = async () => {
                try { 
                    const result = await dynamicLabelJnx.evalAsync(rootFormData || {}, path, {
                        root: rootFormData,
                        formContext
                    }).then(result=>{                        
                        setDynamicLabel(result || title);
                    })
                } catch (error) {
                    setDynamicLabel(title || "Checkbox");
                }
            };
            evaluateDynamicLabel();
        } else {
            setDynamicLabel(title);
        }
    }, [dynamicLabelJnx, formContext, title, path,rootFormData]);

    return (
        showIfAsyncResult ?
            <>
                <FormGroup disabled={readonly || disabled} className="checkbox-custom-field ">

                    {dynamicLabel.trim() ? (<label className="control-label" htmlFor={$id}>
                    
                    <Input
                        id={$id}
                        data-cy={$id}
                        type="checkbox"
                        disabled={readonly || disabled}
                        required={requiredField}
                        checked={!!formData}
                        onChange={onChange}
                        />
                        {dynamicLabel}{requiredField ? <span className="required">*</span> : null}
                  
                    </label>
                ) : null}
                        
                </FormGroup>
            </> : null
    );
}

export default CheckboxField;
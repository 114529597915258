import React, { useMemo } from "react";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { useJnx } from "../../util/jnx";
import getPathFromId from "../../util/getPathFromId";

function DynamicUIComponent(props) {

    const {
        formContext,
        idSchema: { $id } = {},
        formContext: {
            bindings: fcBindings,
            setFormDataValues,
            sideChannel
        },
        uiSchema: {
            'ui:component': uiComponentExpr,
        },
    } = props;

    const path = useMemo(() => getPathFromId($id), [$id]);
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const bindings = useMemo(() => ({
        ...fcBindings,
        rootFormData,
        formObject,
        formContext,
    }), [rootFormData, formObject, fcBindings, formContext]);

    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const uiComponentJnx = useJnx(uiComponentExpr, { functionBinds });

    const Component = useMemo(() => {


        if (!uiComponentJnx) return;
        const value = uiComponentJnx.eval(rootFormData, path, bindings);

        return DynamicUIComponent.COMPONENTS[value];

    }, [rootFormData, $id, uiComponentExpr, uiComponentJnx]);

    if (!Component) return;

    return (
        <Component {...props} />
    );
}

DynamicUIComponent.COMPONENTS = {}

export default DynamicUIComponent;
import React, { useMemo } from 'react';
import useResourceLoader from '../util/useResourceLoader';
import RequisitionsApi, { RequisitionApi } from '../api/RequisitionsApi';
import Loader from '../components/Loader';
import { getObject } from '../util/mapObject';
import { useJnx } from '../util/jnx';


const CURRENT = "current";
const COMPLETE = "complete";
const FINISHED = "finished";
const PROGRESS_CLASSES = {
    current: 'current_state',
    complete: 'complete_state',
    finished: 'complete_state is-last-state'
};

const REQUISITION_SLUG = "requisitions";

const REQUISITION_STEPS = [
    { id: "form.newRequisition", title: "Peticionario", },
    { id: "form.initialApproval", title: "Aprobacion Inicial", showIf: "($params.initialApproval and $isFalsy($params.dspReview)) or ($params.initialApproval and $isTruthy($params.dspReview) and (data.estTotalCost >= 10000 or $data.relatedTo.warehouse))", },
    { id: "form.propertyEndorsement", title: "Endosos Propiedad", showIf: "$params.propertyEndorsement and $data.relatedTo.equipment", },
    { id: "form.facilityEndorsement", title: "Endosos Planta Física", showIf: "$params.facilityEndorsement and $data.relatedTo.facilities", },
    { id: "form.endosoAyudaDeMateriales", title: "Endosos Ayuda De Materiales", showIf: "$params.endosoAyudaDeMateriales and $data.relatedTo.ayudaDeMateriales", },
    { id: "form.endosoBiomedica", title: "Endosos Biomédica", showIf: "$params.endosoBiomedica and $data.relatedTo.biomedica", },
    { id: "form.internalWarehouseEndorsement", title: "Endosos Almacen Interno", showIf: "$params.internalWarehouseEndorsement and $data.relatedTo.warehouse", progress: "state.form.dispatchCompleteFromWarehouse ? 'finished' : null" },
    { id: "form.transportationEndorsement", title: "Endosos Transportación", showIf: "$params.transportationEndorsement and $data.relatedTo.transportation", },
    { id: "form.technologyEndorsement", title: "Endosos Tecnología", showIf: "$params.technologyEndorsement and $data.relatedTo.technology", },
    { id: "form.seguridadEndorsement", title: "Endosos Equipos de Seguridad", showIf: "$params.endosoEquiposDeSeguridad and $data.relatedTo.seguridad", },
    { id: "form.comunicationsEndorsement", title: "Endosos Comunicaciones", showIf: "$params.comunicationsEndorsement and $data.relatedTo.communications", },
    { id: "form.documentsEndorsement", title: "Endosos Documentos", showIf: "$params.documentsEndorsement and $data.relatedTo.documents", },
    { id: "form.generalServicesEndorsement", title: "Endosos Servicios Generales", showIf: "$params.generalServicesEndorsement", },
    { id: "form.reformEndorsement", title: "Endosos Reforma", showIf: "$params.reformEndorsement and $data.reformFunds", },
    { id: "form.liderazgoTransformacionalEndorsement", title: "Endosos Liderazgo Transformacional", showIf: "$params.endosoLiderazgoTransformacional and $data.relatedTo.endosoLiderazgoTransformacional", },
    { id: "form.recoveryEndorsement", title: "Endoso Oficina de Recuperación", showIf: "$params.module5InPadawan and $params.recoveryEndorsement and $params.module5Way2 and (((data.federalProject = 'ARPA' or data.federalProject = 'FEMA') and data.usesFederalFunds) or $isTruthy(metadata.useFemaArpaFund))", },
    { id: "form.administrationEndorsement", title: "Endoso Fondos Federales", showIf: "$params.module5InPadawan and $params.administrationEndorsement and $params.module5Way2 and (((data.federalProject != 'FEMA' and data.federalProject != 'ARPA') and data.usesFederalFunds) or $isFalsy(metadata.useFemaArpaFund))" },
    { id: "form.estimado", title: "Estimado", showIf: "$params.module5InPadawan and $params.estimado and ($data.method = 'Regular' or $params.estimadoContraContrato)" },
    {
        id: "form.finalApproval", title: "Aprobacion Final", showIf: [
            "(",
            "($params.finalApproval and $isFalsy($params.isAsem)) or",
            " (",
            "$params.finalApproval and $params.isAsem and",
            "(",
            "($data.estTotalCost > 75000) or ",
            "($data.priority = 'Emergencia') or ",
            "($data.relatedTo.warehouse or ",
            "$data.relatedTo.equipment or",
            "$data.relatedTo.warehouse or",
            "$data.relatedTo.facilities or",
            "$data.relatedTo.biomedica or",
            "$data.relatedTo.transportation or",
            "$data.relatedTo.technology or",
            "$data.relatedTo.communications or",
            "$data.relatedTo.documents or",
            "$data.relatedTo.seguridad)",
            ")",
            ")",
            ") and $isFalsy($params.moduloAsg)"
        ]
    },
    { id: "form.presupuesto", title: "Presupuesto", showIf: "$params.module5InPadawan and $params.module5Way2", progress: "state.form.completeFromBudget ? 'finished' : null" },
    { id: "form.prits", title: "PRITS", showIf: "$params.module5InPadawan and $params.module5Way2 and data.relatedTo.technology", },
    { id: "form.ogp", title: "OGP", showIf: "(data.estTotalCost >= 50000 or data.category = 1 or data.category = 11) and $isFalsy($params.moduloAsg)", },
    {
        id: "form.administracion", title: "Administración", showIf: `
        $params.module5InPadawan and (
            status[0].id = 'form.administracion' or
            state.form.administracion
        )
    `},
    { id: "form.presupuesto", title: "Presupuesto", showIf: "$params.module5InPadawan and $isFalsy($params.module5Way2)", },
    { id: "form.ogp", title: "OGP", showIf: "$params.module5InPadawan and $isFalsy($params.module5Way2) and $isFalsy($params.moduloAsg)", },
    { id: "form.finalApproval", title: "Aprobacion Final", showIf: "$params.moduloAsg", },
    { id: "event.resendToJedi", title: "Pendiente por Enviar a JEDI", showIf: "status[0].id = 'event.resendToJedi'" },
    {
        id: "event.sentToJedi",
        title: "Oficial de Enlace",
        isExternal: "Jedi",
        prStatus: ['EXTERNALAGENCYREVIEW', 'RETURNEDINACTIVE', 'RETURNAPPROVED'],
        progress: `(
            $statusCode := metadata.statusCode;
            $prs := externalData.history.purchaseRequestStatusCode;
            (
                ($step.id in status.id and $prs[0] in $step.prStatus) or ($statusCode in $step.prStatus)
            ) ? 'current' : (
                $prs~>$reduce(function($acc, $current) { $current in $step.prStatus ? true : false }, 0) ?
                'complete' : ''
            )
        )`
    },
    {
        id: "event.sentToJedi",
        title: "Administración",
        isExternal: "Jedi",
        prStatus: 'AGENCYADMINISTRATIONREVIEW',
        progress: `(
            $prs := externalData.history.purchaseRequestStatusCode;
            (
                $step.id in status.id and $prs[0] = $step.prStatus
            ) ? 'current' : (
                $step.prStatus in $prs ?
                'complete' : ''
            )
        )`,
        showIf: `(
            $prs := externalData.history.purchaseRequestStatusCode;
            $step.prStatus in $prs
        )`
    },
    {
        id: "event.sentToJedi",
        title: "Presupuesto",
        isExternal: "Jedi",
        prStatus: 'AGENCYBUDGETREVIEW',
        progress: `(
            $prs := externalData.history.purchaseRequestStatusCode;
            $prs := externalData.history.purchaseRequestStatusCode;
            (
                $step.id in status.id and $prs[0] = $step.prStatus
            ) ? 'current' : (
                $step.prStatus in $prs ?
                'complete' : ''
            )
        )`,
        showIf: "$isFalsy($params.module5InPadawan)"
    },
    {
        id: "event.sentToJedi",
        title: "OGP",
        isExternal: "Jedi",
        prStatus: 'AGENCYOGPREVIEW',
        progress: `(
            $prs := externalData.history.purchaseRequestStatusCode;
            $prs := externalData.history.purchaseRequestStatusCode;
            (
                $step.id in status.id and $prs[0] = $step.prStatus
            ) ? 'current' : (
                $step.prStatus in $prs ?
                'complete' : ''
            )
        )`,
        showIf: "$isFalsy($params.module5InPadawan) and data.estTotalCost >= 10000"
    },
    {
        id: "event.sentToJedi",
        title: "Enviado a la ASG",
        isExternal: "Jedi",
        prStatusStart: 'INITIALREVIEW',
        prNotStatus: ['EXTERNALAGENCYREVIEW', 'RETURNEDINACTIVE', 'RETURNAPPROVED'],
        eventStop: "completed",
        showIf: "(($isTruthy($params.estimado) and data.estTotalCost >= 15000) or $isFalsy($params.estimado) or $isTruthy($params.endosoAyudaDeMateriales))",
        progress: `(
            $statusCode := metadata.statusCode;
            $prs := externalData.history.purchaseRequestStatusCode;
            (state.jediSubmission and $step.prStatusStart in $prs) ? (
                state.form.returnFromJedi or $prs[0] in $step.prNotStatus or state.event[$step.eventStop] ? 'complete' : 'current'
            ) : ''
        )`
    },
    {
        id: "event.sentToJedi",
        title: "Enviado al Delegado Comprador",
        isExternal: "Jedi",
        prStatusStart: 'INITIALREVIEW',
        prNotStatus: ['EXTERNALAGENCYREVIEW', 'RETURNEDINACTIVE', 'RETURNAPPROVED'],
        eventStop: "completed",
        showIf: "($isTruthy($params.estimado) and (data.estTotalCost < 15000 or $isFalsy(data.estTotalCost))) and $isFalsy($params.endosoAyudaDeMateriales)",
        progress: `(
            $statusCode := metadata.statusCode;
            $prs := externalData.history.purchaseRequestStatusCode;
            (state.jediSubmission and $step.prStatusStart in $prs) ? (
                state.form.returnFromJedi or $prs[0] in $step.prNotStatus or state.event[$step.eventStop] ? 'complete' : 'current'
            ) : ''
        )`
    },
    {
        id: "event.completed", title: "Completado",
        className: 'is-last-state'
    }
]


function RequisitionProgress({ requisition, slug }) {

    const [ENTITY_STEPS, loadingSteps] = useResourceLoader(async () => {
        if (requisition) {
            const resp = await RequisitionApi.getUWEEntityPreviousLog({ entityId: requisition.id });
            if (slug === REQUISITION_SLUG) {
                return mergeRequisitionSteps(resp, REQUISITION_STEPS);
            }
            return resp;
        }
    }, [requisition]);

    const Component = slug === REQUISITION_SLUG ? RequisitionProgressStep : UWEEntityProgressStep;

    return loadingSteps || !ENTITY_STEPS?.length || !slug ? <Loader /> : (ENTITY_STEPS.map((step, idx) => <Component
        key={idx} step={step} requisition={requisition}
    />));

}


function RequisitionProgressStep({ requisition, step }) {
    const { id, title, showIf, className, progress } = step;

    if (!id) return;

    const showIfJnx = useJnx(showIf);

    const progressJnx = useJnx(progress);

    const bindings = useMemo(() => ({
        step,
        params: requisition?.organizationalUnit?.workflowParams,
        data: requisition?.data,
    }), [requisition, step]);

    const show = useMemo(() => showIfJnx ? showIfJnx.eval(requisition, '', bindings) : true, [showIfJnx, requisition, bindings]);
    const progressValue = useMemo(() => progressJnx && progressJnx.eval(requisition, '', bindings), [progressJnx, requisition, bindings]);

    const classProgress = useMemo(() => {
        if (progressValue !== null) return progressValue;

        const { status = [], state = {} } = requisition || {};


        if (status?.[0]?.id === id || status?.[0]?.id === id) {
            return CURRENT;
        } else if (id && ((state?.[id] || getObject(state, id)) || (state?.[`${id}Arrive`] || getObject(state, `${id}Arrive`)))) {
            return COMPLETE;
        }
    }, [id, requisition, progressValue]);

    return show ? (
        <div className={`rowProgress ${className || ''} ${PROGRESS_CLASSES[classProgress] || ""}`}>{title}</div>
    ) : null;

}

function UWEEntityProgressStep({ requisition: entity, step }) {

    const className = "";

    const classProgress = useMemo(() => {

        const { status = [], state = {} } = entity || {};

        if (status?.[0]?.name === step?.title && status?.[0]?.type === "end") {
            return FINISHED;
        } else if (status?.[0]?.name === step?.title) {
            return CURRENT;
        } else {
            return COMPLETE;
        }
    }, [step, entity]);

    return <div className={`rowProgress ${className || ''} ${PROGRESS_CLASSES[classProgress] || ""}`}>{step?.title}</div>
}

const mergeRequisitionSteps = (originalObj, newObj) => {

    if (!originalObj || !newObj) return;

    Object.entries(newObj).forEach(([key, obj]) => {
        const existIndex = originalObj.findIndex(x => x.id === obj.id && x.title.toLowerCase() === obj.title.toLowerCase());
        if (existIndex !== -1) {
            if (!originalObj[existIndex]?.id) return;
            const originalShowIf = originalObj[existIndex].showIf;
            if (originalShowIf && obj.showIf && originalShowIf !== obj.showIf) {
                originalObj.push({ ...originalObj[existIndex], ...obj });
                return;
            }
            originalObj[existIndex] = { ...originalObj[existIndex], ...obj };
        }
    });

    const complete = newObj.find(x => x.id === "event.completed");

    const externalStatus = newObj.filter(x => x.isExternal);
    originalObj = [...originalObj, ...externalStatus];

    const completeIndex = originalObj.findIndex(x => x.id === complete.id) === -1 ? originalObj.push(complete) : null;

    return originalObj;
}

export default RequisitionProgress;

import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import { lookupOptions } from "../../hooks/useOptionsLookup";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { useJnx } from "../../util/jnx";
import getPathFromId from "../../util/getPathFromId";


function Autocomplete(props) {
    const {
        schema: {
            title,
            lookup,
            type
        },
        formContext: {
            setFormDataValues,
            sideChannel
        },
        formData: value,
        onChange,
        disabled,
        required,
        idSchema: { $id } = {},
        uiSchema: {
            'ui:readonly': uiReadonly,
            'ui:onSelect': onSelectExpr,
            'ui:labelFontSize': labelFontSize,
            'ui:classNames': classNames,
            'ui:showAllOptions':showAllOptions = false
        },
        readonly: propReadonly
    } = props;
    const {
        resource = "Options",
        jnx: apiResponseJnxExpr,
        params = {},
        api,
        id,
        label: labelExpr,
        searchParam,
        setObjectAs,
        allowNoValue = true,
        noValue = '',
        canCreate = false
    } = lookup || {};

    const Component = useMemo(() => canCreate ? AsyncCreatableSelect : AsyncSelect, [canCreate]);

    const promiseRef = useRef();

    const rootFormData = useSideChannelSubscription(sideChannel, 0);

    const labelJnx = useJnx(labelExpr);
    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const onSelectJnx = useJnx(onSelectExpr, { functionBinds });

    const path = useMemo(() => getPathFromId($id), [$id]);

    const [current, setCurrent] = useState(value);
    useDebouncedEffect(() => {
        if (current?.label !== value) {

            if (onSelectJnx && current.object) {
                setTimeout(() => {
                    onSelectJnx.eval(current.object, '', { fieldPath: path, object: current.object });
                }, 500);
            }

            onChange(current.value)
        }
    }, [current], 450);
    useEffect(() => setCurrent(typeof value === "string" ? { label: value } : value), [value]);

    const readonly = uiReadonly || propReadonly;

    const [loading, setLoading] = useState(true);


    const promiseOptions = useCallback((inputValue) => {
        if (showAllOptions || inputValue.length > 2) {
            setLoading(true);
            const search = { searchParam: inputValue };
            search.promise = new Promise((resolve) => {
                search.resolve = resolve;
            });
            promiseRef.current = search.promise;
            setTimeout(async () => {
                if (promiseRef.current === search.promise) {
                    const options = await lookupOptions({ ...lookup, params: { ...lookup.params, [searchParam]: inputValue } }, rootFormData, path);
                    const dataParse = (options || []).map((obj) => {
                        return {
                            object: obj,
                            value: obj[id],
                            label: labelJnx.eval(obj)
                        };
                    });
                    search.resolve(dataParse);
                    setLoading(false); 
                }
            }, 300);
            return search.promise;
        }
        return [];
    }, [rootFormData, lookupOptions, id, labelJnx, showAllOptions]);

    return (
        <div className="form-group">
            {title.trim() ? (<label className={`control-label ${labelFontSize}`} >
                {title}{required ? <span className="required">*</span> : null}
            </label>) : null}
            <div className={`autoComplete ${classNames}`} data-cy={$id}>
                <Component
                    required={required}
                    isDisabled={readonly || disabled}
                    cacheOptions={false}
                    defaultOptions={showAllOptions}
                    value={current || {}}
                    isLoading={loading}
                    loadOptions={promiseOptions}
                    onChange={(value) => {setCurrent(value); setLoading(false); }}
                    formatCreateLabel={() => "Ingresar esta opción manual"}
                    onCreateOption={(e) => {setCurrent({ value: e, object: { identifier: "", supplierID: "" }, label: e });  setLoading(false);}}
                />
            </div>
        </div>
    )
}

export default Autocomplete;
import SendEmail from "./SendEmail";
import FileField from "./FileField";
import Button from "./Button";
import RequisitionZipDownloadField from "./RequisitionZipDownloadField";
import BoolYesNoField from "./BoolYesNoField";
import IconCheckField from "./IconCheckField";
import CheckboxToggleButtonField from "./CheckboxToggleButtonField";
import HideObjectField from "./HideObjectField";
import RiskMatrixField from "./RiskMatrixField";
import RiskMatrixFieldBusiness from "./RiskMatrixFieldBusiness";
import SignatureField from "./SignatureField";
import CompositeInputField from "./CompositeInputField";
import ComputedField from "./ComputedField";
import LookupFormField from "./LookupFormField";
import LookupFormStaticField from "./LookupFormStaticField";
import LookupMultiSelectCheckboxesFormField from "./LookupMultiSelectCheckboxesFormField";
import TextInput from "./TextInput";
import PhoneInput from "./PhoneInput";
import TimerField from "./TimerField";
import MoneyInput from "./MoneyInput";
import CedulaInput from "./CedulaInput";
import StaticField from "./StaticField";
import ArrayField from "./ArrayField";
import Autocomplete from "./AutocompleteField";
import StaticTextBlurb from "./StaticTextBlurb";
import HtmlEditor from "./HtmlEditor";
import Notes from "./Notes";
import ImageCrop from "./ImageCrop";
import DynamicUIComponent from "./DynamicUIComponent";
import CheckboxField from "./CheckboxField";

const customFields = {
    SendEmail,
    FileField,
    Button,
    RequisitionZipDownloadField,
    RiskMatrixField,
    RiskMatrixFieldBusiness,
    ComputedField,
    SignatureField,
    CompositeInputField,
    HideObjectField,
    BoolYesNoField,
    BoolYesNo: BoolYesNoField,
    IconCheckField,
    CheckboxToggleButtonField,
    LookupFormField,
    LookupFormStaticField,
    LookupMultiSelectCheckboxesFormField,
    TextInput,
    PhoneInput,
    TimerField,
    MoneyInput,
    CedulaInput,
    StaticField,
    ArrayField,
    Autocomplete,
    StaticTextBlurb,
    HtmlEditor,
    Notes,
    ImageCrop,
    DynamicUIComponent,
    CheckboxField
}

DynamicUIComponent.COMPONENTS = customFields;

export default customFields;